@import url("https://fonts.googleapis.com/css2?family=Great+Vibes:wght@300;400;500;600;900&display=swap");

.about-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 20px;
  border-top: 2px solid black;
  border-bottom: 2px solid black;

  background-color: #e2e5e7;
  border-radius: 20px;
  margin: 10px;
}

.about-title {
  font-size: 12px;
  font-weight: 500;
  padding-bottom: 10px;
  font-family: "Inter", sans-serif;

  display: flex;
  flex-direction: column;
  text-align: start;
  width: 100%;
}

.about-inner-title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.about-us-img {
  width: 100%;
  max-width: 600px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.about-text {
  width: 100%;
  flex-wrap: wrap;
  overflow: hidden;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  text-align: justify;
  font-family: "Great Vibes", cursive;
}

.text-style {
  margin-bottom: 20px;
}

@media (min-width: 778px) {
  .about-wrapper {
    padding: 30px 20px;
    margin: 25px 6.5%;
  }

  .about-title {
    font-size: 25px;
    width: 100%;
  }
}

@media (min-width: 1100px) {
  .about-wrapper {
    flex-direction: row;
    justify-content: space-between;
  }

  .about-text {
    width: 100%;
    font-size: 25px;
    line-height: 25px;
    padding: 0px 30px;

    display: flex;
    align-items: center;
  }

  .about-us-img {
    max-width: 700px;
  }
}

@media (min-width: 1100px) and (max-width: 1300px) {
  .about-text{
    font-size: 20px;
    line-height: normal;
  }
}